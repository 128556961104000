import React, {useRef, useState} from "react";
import emailjs from "@emailjs/browser";
import {AiFillPhone} from "react-icons/ai";
import {SiGooglemaps, SiGmail} from "react-icons/si";
import {FaFacebookF, FaBusinessTime} from "react-icons/fa";
import {BsFillCalendar2DateFill} from "react-icons/bs";


const Contact=()=>{
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_7mamfhd', 'template_1rmcmsa', form.current, 'MORC9XYThf6jeoxc-')
          .then((result) => {
            alert("تم ارسال الرسالة")
          }, (error) => {
            alert("وجد اشكال عند الارسال يرجى إعادة المحولة");
          });
      };
    return(
        <div id="contact" className="bg-[#1b1b1b] text-[#f1f1f1] w-full h-fit py-3 border-b-2 border-[#f1f1f1]">
            <h1 className="h-fit w-fit m-auto my-4 font-bold text-[30px]">تواصل معنا</h1>
            <div className="w-fit h-fit m-auto flex flex-col md:flex-row">
                <div className="w-[400px] h-fit flex flex-col p-12 md:mr-5">
                    <a href="#" className="flex flex-row items-center justify-end">041385548 <AiFillPhone className="ml-3 text-red-600"/></a>
                    <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=quds101arabi@gmail.com&su=Livres&body=Body%20Text" className="flex flex-row items-center justify-end">quds101arabi@gmail.com <SiGmail className="ml-3 text-red-600"/></a>
                    <a href="https://www.facebook.com/darelqods" className="flex flex-row items-center justify-end">Dar El Qods Arabi <FaFacebookF className="ml-3 text-red-600"/></a>
                    <a href="#" className="flex flex-row items-center justify-end">Centre d'affeire el mountazah rue Harouni Bouziane <SiGooglemaps className="ml-3 text-red-600"/></a>
                    <a href="#" className="flex flex-row items-center justify-end">من السبت الى الخميس <BsFillCalendar2DateFill className="ml-3 text-red-600"/></a>
                    <a href="#" className="flex flex-row items-center justify-end">من الساعة 9 الى 17 <FaBusinessTime className="ml-3 text-red-600"/></a>
                </div>
                <div className="bg-[#f1f1f1] text-[#1b1b1b] w-[300px] md:w-[500px] h-fit flex flex-col py-4 m-auto">
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="w-full h-fit flex flex-row justify-around m-auto py-3 mb-4">
                            <label className="flex flex-col w-[40%]">
                                <span  className="text-red-600 mb-3">اللقب</span>
                                <input type="text" className="w-full border border-[#1b1b1b] text-right pr-1" name="form_lastname" required/>
                            </label>
                            <label className="flex flex-col w-[40%]">
                                <span  className="text-red-600 mb-3">الاسم</span>
                                <input type="text" className="w-full border border-[#1b1b1b] text-right pr-1" name="from_name" required/>
                            </label>
                        </div>
                        <div className="w-full h-fil px-7 mb-4">
                            <label className="flex flex-col w-full">
                                <span className="text-red-600 mb-3">البريد الإلكتروني</span>
                                <input type="email" className="w-full border border-[#1b1b1b] text-right pr-1" name="from_email" required/>
                            </label>
                        </div>
                        <div className="w-full h-fil px-7 mb-4">
                            <label className="flex flex-col w-full">
                                <span className="text-red-600 mb-3">التفاصيل</span>
                                <textarea name="message" id="msg" rows="8" className="w-full focus:caret-red-600 border border-[#1b1b1b] text-right pr-1" ></textarea>
                            </label>
                        </div>
                        <div className="w-full h-fil px-7 mb-4">
                            <input type="submit" value="إرسال" className="w-full h-fill bg-green-900 text-[#f1f1f1] py-2 hover:bg-yellow-500 duration-200"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}


export default Contact;