import React, {useState, useEffect} from "react";
import {GoThreeBars} from "react-icons/go";
import {CgClose} from "react-icons/cg";
import logo from "../imgs/logo qods.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { collection, getDoc, doc } from "firebase/firestore"; 
import db from "./connect";

const Header=()=>{
    const [nav, SetNav]=useState(true);
    const HandelNav=()=>{
        SetNav(!nav);
    }
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      const [txt, setTxt]= useState("");
      const fetchtext=async ()=>{
        const docRef = doc(db, "annonce", "important");
        const docSnap = await getDoc(docRef);
        //const data=await docRef.get();
        setTxt(docSnap.data().text)
      }
      useEffect(()=>{
        fetchtext()
      });
    return(
        <div id="header" className="top-0 flex flex-col w-full h-fit shadow-xl" data-aos="fade-down">
            <div className="importent bg-red-600 text-white w-full h-fit py-2">
                <p id="textAnim" className="w-fit block h-fit m-auto  whitespace-nowrap px-3">{txt}</p>
            </div>
            <div className="nav w-full h-fit">
                <nav className="flex justify-between items-center w-[92%] mx-auto py-2 px-10">
                    <div onClick={HandelNav} className="md:hidden">
                        {!nav ? <CgClose onClick={(e)=>{
                            document.getElementById("links").style.width=0;
                        }}/>: <GoThreeBars onClick={(e)=>{
                        document.getElementById("links").style.width="100%";
                        document.getElementById("links").style.zIndex="99px";
                    }}/>}
                    </div>
                    
                    <div id="links" className="absolute md:shadow-none md:static shadow-xl bg-[#f1f1f1] md:min-h-fit h-fit top-[105px] left-0 overflow-x-hidden w-0 duration-300 md:w-full py-6">
                        <ul className="md:flex hidden  md:flex-row flex-col items-center gap-[4vw]">
                            <li className="my-3 md:my-0 hover:cursor-pointer hover:text-yellow-500 duration-150"><a href="#contact">للتواصل معنا</a></li>
                            <Link to={`/livres`}>
                                <li className="my-3 md:my-0 hover:cursor-pointer hover:text-yellow-500 duration-150">الكتب</li>
                            </Link>
                            <Link to={`/`}>
                                <li className="my-3 md:my-0 hover:cursor-pointer hover:text-yellow-500 duration-150"><a href="#banner">من نحن</a></li>
                            </Link>
                            <Link to={`/`}>
                                <li className="my-3 md:my-0 hover:cursor-pointer hover:text-yellow-500 duration-150">الرئيسية</li>
                            </Link>
                        </ul>
                        <ul className="flex md:hidden flex-col items-center gap-[4vw]">
                            <Link to={`/`}>
                                <li onClick={()=>{document.getElementById("links").style.width=0; SetNav(!nav)}} className="my-3 md:my-0 hover:cursor-pointer hover:text-yellow-500 duration-150">الرئيسية</li>
                            </Link>
                            <Link to={`/`}>
                                <li onClick={()=>{document.getElementById("links").style.width=0; SetNav(!nav)}} className="my-3 md:my-0 hover:cursor-pointer hover:text-yellow-500 duration-150"><a href="#banner">من نحن</a></li>
                            </Link>
                            <Link to={`/livres`}>
                                <li onClick={()=>{document.getElementById("links").style.width=0; SetNav(!nav)}} className="my-3 md:my-0 hover:cursor-pointer hover:text-yellow-500 duration-150">الكتب</li>
                            </Link>
                            <li onClick={()=>{document.getElementById("links").style.width=0; SetNav(!nav)}} className="my-3 md:my-0 hover:cursor-pointer hover:text-yellow-500 duration-150"><a href="#contact">للتواصل معنا</a></li>
                        </ul>
                    </div>
                    <div className="logo float-right h-12 w-22">
                      <Link to={`/`}>
                        <img src={logo} alt="logo" className="w-full h-full"/>
                      </Link>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header;