import React, {useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";



const Livre=({livre})=>{
    const {img, titre, auteur, pages, resume, volume, editeur }=livre;
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return(
        <div className="items p-5 w-full h-fit max-w-[300px] mx-auto shadow-2xl cursor-pointer bg-white hover:-translate-y-2 duration-200 animate-fade-up my-6">
            <img className="mb-8 w-full h-[230px]" src={img} alt={titre} />
            <div className="mb-4 flex gap-4">
                <div className="text-center w-full bg-yellow-600 text-white text-[15px]">{auteur}</div>
                <div className="text-center w-full bg-red-600 text-white text-[.7rem]">{titre}</div>
            </div>
            <div className="mb-4 flex gap-x-2 w-full h-fit">
                <div className="m-auto w-fit h-fit text-[20px] flex flex-row">{pages} <h4 className="ml-2"> :عدد الصفحات</h4></div>
            </div>
            <button className="bg-green-800 w-full text-white py-2">التفاصيل</button>
        </div>
    );
}

export default Livre;