import React, {useEffect, useState} from "react";
import livre from "../imgs/banner.jpg";
import Livre from "./livre";
import {Link} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { collection, getDocs, limit, query } from "firebase/firestore";
import {ref, getDownloadURL, getStorage} from "firebase/storage" ;
import  db from "./connect";

const Expml=()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      
    const livres=[
        {
           img: livre,
           titre: "titre1",
           auteure: "auteur1",
           page: 200
        },
        {
            img: livre,
            titre: "titre2",
            auteure: "auteur2",
            page: 200
         },
         {
            img: livre,
            titre: "titre3",
            auteure: "auteur3",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
    ];
    const [livress, setLivress]= useState([]);
    const fetchlivret=async ()=>{
        let list=[];
        const docRef = query(collection(db,"livres"), limit(4));
        const docSnap = await getDocs(docRef);
        const storage= getStorage();
        //const data=await docRef.get();
        docSnap.forEach((data)=>{
            getDownloadURL(ref(storage, data.data().img)).then((url)=>{
                list.push({titre: data.data().titre, auteur: data.data().auteur, pages: data.data().pages, resume: data.data().resume, editeur: data.data().editeur, volume: data.data().volume, img: url});
               setLivress([...list])
            });
        });
      }
      useEffect(()=>{fetchlivret()},[]);
    return(
        <div className="w-[90%] h-fit mt-[30px] m-auto py-8" data-aos="fade-up" data-aos-duration="900">
            <h1 className="my-4 h-fit w-fit m-auto text-[30px] font-bold hover:cursor-pointer">عينة من الكتب</h1>
            <div className="grid md:grid-cols-4 gap-4 my-8 ">
                {
                    livress.map((liv, index)=>{
                        return(
                            <Link to={`/details/${liv.titre}`} key={index}>
                                <Livre livre={liv}/>
                            </Link>
                        );
                    })
                }
            </div>
            <div className="w-fit m-auto mt-5 h-fit bg-red-400 items-center">
                <Link to={`/livres`}>
                    <button className="text-white bg-green-900 w-[150px] md:w-[200px] py-2 hover:bg-yellow-500 duration-200">المزيد</button>
                </Link>
            </div>
        </div>
    );
}

export default Expml;