import React, {useEffect} from "react";
import banner from "../imgs/banner.jpg";
import logo from "../imgs/logo qods.png";
import{SiGmail} from "react-icons/si";
import {FaFacebookF} from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

const Banner=()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      
    return(
        <div id="banner" className="md:h-fit w-[90%] flex flex-col md:flex-row m-auto">
            <div className="img w-full h-[50%] md:w-[50%] md:h-full animate-fade-up" >
                <img src={banner} alt=""  className="h-full w-full" />
            </div>
            <div className="img w-full h-[50%] md:w-[50%] md:h-full  text-center animate-fade-left" >
                <div className="w-fit h-fit m-auto flex flex-col">
                    <img src={logo} alt="logo" className="img md:w-[150px] md:h-[150px] w-[100px] mx-auto mb-4"/>
                    <h2 id="quds" className="my-5 font-bold font-arab text-[20px]">دار القدس العربي للنشر والتوزيع</h2>
                    <p className="text-center p-2">دار نشر جزائرية تهتم بطباعة ونشر وتوزيع الكتب ذات القيمة العلمية والمضمون الهادف, تأسست سنة
                            2008 بمدينة وهران بالغرب الجزائري. لها تواجد ثقافي في العديد من المناسبات الوطنية والدولية.
                            حاضرة دائما إلى جانب قرائها بمحافظتها على التواصل الدائم من خلال إبرازها لآخر منشوراتها عبر
                            وسائل الإعلام المكتوبة والمسموعة والمرئية.
                            دار القدس العربي لنشر والتوزيع حاضرة دائما في الصالونات والمعارض الدولية للكتاب عبر العديد من
                            الأقطار العربية والأجنبية من خلال مشاركتها بمنتوجها الجديد من الكتب والمجلات العلمية الجامعية
                            التي تختص بإصدار العديد من المقالات ذات الطابع المتجدد في شتي التخصصات (الأدبية- التاريخية-
                            الفلسفية – الدينية...........)
                            دارا لقدس العربي للنشر والتوزيع ومنذ نشأتها اتسمت بالحفاظ على طابعها العلمي المحافظ والذي
                            تجدونه من خلال نشرها لأزيد من 500 عنوان ما بين كتاب ومجلة علمية وأدبية وبعد ة لغات أجنبية
                            وعربية..........
                            لمزيد من الاطلاع والمعلومات حول دار القدس العربي للنشر والتوزيع</p>
                    <div className="w-fit float-right m-auto h-fit flex flex-row mt-6">
                        <a href="https://www.facebook.com/darelqods" className="flex flex-row items-center justify-end"><button className="bg-green-900 text-white p-2 mr-3 hover:bg-yellow-500 duration-200"><FaFacebookF size={20}/></button></a>
                        <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=quds101arabi@gmail.com&su=Livres&body=Body%20Text"><button className="bg-green-900 text-white p-2 mr-3 hover:bg-yellow-500 duration-200"><SiGmail size={20}/></button></a>
                       <a href="#contact"><button className="bg-green-900 text-white md:w-[200px] w-[150px] py-2 hover:bg-yellow-500 duration-200">للتواصل معنا</button></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;