import React from "react";
import Header from "./comp/header";
import ContainerH from "./comp/containerH";
import {Route, Routes} from "react-router-dom";
import Details from "./comp/details";
import Livres from "./comp/livres";
import Contact from "./comp/contact";
import Footer from "./comp/footer";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<ContainerH />}/>
        <Route path="/containerH" element={<ContainerH />}/>
        <Route path="/details/:id" element={<Details />}/>
        <Route path="/livres" element={<Livres />}/>
      </Routes>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
