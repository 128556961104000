import React, {useEffect, useState}from "react";
import livre from "../imgs/banner.jpg";
import Livre from "./livre";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { collection, getDocs, limit, query } from "firebase/firestore";
import {ref, getDownloadURL, getStorage} from "firebase/storage" ;
import  db from "./connect";


const Livres=()=>{

    /*const livres=[
        {
           img: livre,
           titre: "titre1",
           auteure: "auteur1",
           page: 200
        },
        {
            img: livre,
            titre: "titre2",
            auteure: "auteur2",
            page: 200
         },
         {
            img: livre,
            titre: "titre3",
            auteure: "auteur3",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
         {
            img: livre,
            titre: "titre4",
            auteure: "auteur4",
            page: 200
         },
    ];*/
    const [livress, setLivress]= useState([]);
    const fetchlivret=async ()=>{
        let list=[];
        const docRef = collection(db,"livres");
        const docSnap = await getDocs(docRef);
        const storage= getStorage();
        //const data=await docRef.get();
        docSnap.forEach((data)=>{
            getDownloadURL(ref(storage, data.data().img)).then((url)=>{
                list.push({titre: data.data().titre, auteur: data.data().auteur, pages: data.data().pages, resume: data.data().resume, editeur: data.data().editeur, volume: data.data().volume, img: url});
               setLivress([...list])
            });
        });
      }
      useEffect(()=>{fetchlivret()},[]);

      const [searchTerm, setSearchTerm]= useState("");

      const [itemOffset, setItemOffset] = useState(0);
      const itemsPerPage= 8;
      const endOffset = itemOffset + itemsPerPage;
      const currentItems = livress.slice(itemOffset, endOffset);
      const pageCount = Math.ceil(livress.length / itemsPerPage);
      const handlePageClick = (event) => {
         const newOffset = (event.selected * itemsPerPage) % livress.length;
         setItemOffset(newOffset);
      };
      console.log(livress.length);
      console.log(pageCount);

      return (
         <div className="w-[90%] h-fit m-auto mt-[30px]">
            <div className="w-[250px] h-[50px] m-auto my-10">
               <div dir="rtl" className="w-full h-full flex items-center px-2 text-gray-700 focus:text-[#1b1b1b]">
                  <input onChange={(event)=>{
                     setSearchTerm(event.target.value);
                  }} type="text"  className="w-full h-full bg-white px-3 border bottom-[1px] border-[#1b1b1b]" placeholder="عنوان الكتاب أو اسم المؤلف"/>
               </div>
            </div>
            <div id="elements" className="grid md:grid-cols-4 gap-4">
                {
                  currentItems.filter((val)=>{
                     if (searchTerm?.toLowerCase ==="") {
                        return val;
                     }else if (val.titre.toLowerCase().includes(searchTerm.toLowerCase()) || val.auteur.toLowerCase().includes(searchTerm.toLowerCase())) {
                        return val;
                     }
                  }).map((liv, index)=>{
                     return(
                        <Link to={`/details/${liv.titre}`} key={index} className="animate-fade-up">
                           <Livre livre={liv}/>
                        </Link>
                     );
                  })
                }
            </div>
            <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page"
            previousLinkClassName="next-prev"
            nextLinkClassName="next-prev"
            activeClassName="page-active"
            />
         </div>
      );
   
   /* return(
        <div className="w-[90%] h-fit m-auto mt-[30px]">
           /* <div className="grid md:grid-cols-4 gap-4">
                {
                    livres.map((liv, index)=>{
                        return(
                            <Link to={`/details/${liv.titre}`} key={index}>
                                <Livre livre={liv}/>
                            </Link>
                        );
                    })
                }
            </div>
        </div>
    );*/
}

export default Livres;