import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {AiOutlineUser} from "react-icons/ai";
import {GoBook} from "react-icons/go";
import {getDoc, doc} from "firebase/firestore";
import {ref, getDownloadURL, getStorage} from "firebase/storage" ;
import  db from "./connect";

const Details=()=>{
    const {id} = useParams();
    const [livress, setLivress]= useState([]);
    console.log(id)
    const fetchlivret=async ()=>{
        const docRef = doc(db,"livres", id);
        const docSnap = await getDoc(docRef);
        const storage= getStorage();
        //const data=await docRef.get();
            getDownloadURL(ref(storage, docSnap.data().img)).then((url)=>{
               setLivress({titre: docSnap.data().titre, auteur: docSnap.data().auteur, pages: docSnap.data().pages, resume: docSnap.data().resume, editeur: docSnap.data().editeur, volume: docSnap.data().volume, img: url})
            });
      }
      useEffect(()=>{fetchlivret()},[]);
    return(
        <div className="w-[90%] h-fit m-auto mt-[30px] px-5 mb-[350px]">
            <div className="titre w-full h-fit">
                <h1 className="font-bold text-[30px] mb-4">{livress.titre}</h1>
            </div>
            <div className="info w-fit md:w-full h-fit grid gap-4 m-auto md:flex md:flex-row md:justify-between">
                <div className="flex flex-row items-center justify-end px-4"><span className="mr-2 text-[25px]">{livress.pages} </span><h3 className="text-[20px] ml-3 md:text-[20px]"> :عدد الصفحات</h3><GoBook className="ml-2 text-green-900 text-[20px]"/></div>
                <div className="flex flex-row items-center justify-end px-4"><span className="mr-2 text-[25px]">{livress.editeur} </span><h3 className="text-[20px] ml-3 md:text-[20px]"> :دار النشر</h3><GoBook className="ml-2 text-green-900 text-[20px]"/></div>
                <div className="flex flex-row items-center justify-end px-4"><span className="mr-2 text-[25px]">{livress.auteur} </span><h3 className="text-[20px] ml-3 md:text-[20px]"> :الكاتب</h3><AiOutlineUser className="ml-2 text-green-900 text-[20px]"/></div>
            </div>
            <div className="w-full h-fit flex flex-col mt-8 md:flex-row">
                <div className="w-full min-h-[250px] max-h-[255px] md:w-[50%] md:h-[60px] float-left pt-4">
                    <img src={livress.img} alt={livress.img} className="h-[300px]"/>
                </div>
                <div className="w-full min-h-[300px] max-h-[300px] md:w-[50%] mt-10 md:mt-1 float-left pt-4">
                    <h3 className="mr-2 text-[20px] text-center my-4">ملخص</h3>
                    <p className="w-full h-fit text-center">{livress.resume}</p>
                </div>
            </div>
            
        </div>
    );
}

export default Details;