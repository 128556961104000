import React from "react";
import Banner from "./banner";
import Expml from "./expml";


const ContainerH=()=>{
    return(
        <div className="mt-[20px]">
            <Banner />
            <Expml data-aos="fade-up" data-aos-duration="900"/>
        </div>
    );
}

export default ContainerH;