// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDoc, doc } from "firebase/firestore"; 
import {getStorage,ref} from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBWbsgTtPbwrqt84rLUgPb1KJmuftC_60k",
  authDomain: "qudsarabi-efc1a.firebaseapp.com",
  projectId: "qudsarabi-efc1a",
  storageBucket: "qudsarabi-efc1a.appspot.com",
  messagingSenderId: "175044980518",
  appId: "1:175044980518:web:bca1c58ed6e6a11922dbe7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage= getStorage(app);
const storegeRef= ref(storage);
export default db;