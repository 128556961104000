import React from "react";



const Footer=()=>{
    return(
        <footer className="bg-[#1b1b1b] py-4 text-center text-[#f1f1f1] bottom-0">
            <div className="container">
                Copyright &copy; 2023. All right reserved
            </div>
        </footer>
    );
}

export default Footer;